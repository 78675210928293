<template lang="pug">
div
  Guest
  Influencers
</template>

<script>
import Guest from "./Guest";
import Influencers from "./Influencers/index";

export default {
  components: { Guest, Influencers },
};
</script>
