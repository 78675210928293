<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(color="secondary", v-on="on") Create an Influencer Account
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Create an influencer account
      v-card-text
        v-text-field(label="Account Name", v-model="name")
        v-text-field(
          label="Phone",
          v-model="phone",
          v-mask="\"(###) ###-####\""
        )
        v-text-field(label="Email", v-model="email")
        v-textarea(label="Introduction", v-model="introduction")
        .subtitle-2 Social Profiles
        v-row(
          v-for="(item, index) in social_profiles",
          :key="index",
          dense,
          wrap,
          align="center"
        )
          v-col(cols="3")
            span {{ item.name }}
          v-col(cols="9")
            v-text-field(v-model="item.url", single-line, prefix="https://")
        v-checkbox(
          label="By creating an influencer account with Selflane, your understand and agree to our Term of Use for Selflane Influencers",
          v-model="agreed",
          color="secondary"
        )
      v-card-actions
        v-btn.text-capitalize(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Create an Influencer Account
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      name: "",
      introduction: "",
      phone: "",
      email: "",
      agreed: false,
      social_profiles: [
        { name: "Website", url: "" },
        { name: "Facebook", url: "" },
        { name: "Instagram", url: "" },
        { name: "Twitter", url: "" },
        { name: "TikTok", url: "" },
      ],
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.agreed;
    },
  },
  methods: {
    ...mapActions(["addInfluencer"]),
    reset() {
      this.name = "";
      this.introduction = "";
      this.phone = "";
      this.email = "";
      this.social_profiles = [
        { name: "Website", url: "" },
        { name: "Facebook", url: "" },
        { name: "Instagram", url: "" },
        { name: "Twitter", url: "" },
        { name: "TikTok", url: "" },
      ];
      this.agreed = false;
    },
    async submit() {
      this.loading = true;
      const params = {
        name: this.name.trim(),
        introduction: this.introduction,
        phone: this.phone.replace(/[^\d]/g, ""),
        email: this.email,
        social_profiles: this.social_profiles,
      };
      try {
        const res = await this.axios.post(
          "/influencer/accounts/create",
          params
        );
        this.addInfluencer(res.data);
        this.reset();
        this.dialog = false;
      } catch (err) {
        if (err.response) alert(err.response.data || "Failed");
      }
      this.loading = false;
    },
  },
};
</script>
