import Main from './index.vue'
import Tax from './Tax/index.vue'
import Payments from './Payments/index.vue'

export default [{
  path: '/finances',
  component: Main,
  meta: {
    auth: true
  },
  children: [{
    path: '',
    redirect: 'payments'
  }, {
    path: 'tax',
    component: Tax
  }, {
    path: 'payments',
    component: Payments
  }, {
    path: 'tax',
    component: Tax
  }]
}]