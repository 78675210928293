<template lang="pug">
div
  v-btn.text-capitalize(
    @click="open()",
    outlined,
    small,
    depressed,
    color="grey darken-2"
  ) Edit
    v-icon(right, small) mdi-pencil
  v-dialog(v-model="dialog", width="350")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Basic Information
      v-card-text
        v-form(@submit.prevent="submit")
          v-text-field(v-model="phone", v-mask="phoneMask", label="Phone")
          v-text-field(label="Email", v-model="email")
          v-text-field(
            label="CC Recipients",
            v-model="carboncopy",
            hint="Extra email recipients. Separate with comma.",
            placeholder="a@gmail.com, b@gmail.com"
          )
          v-textarea(label="Introduction", v-model="introduction")
          v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      phone: "",
      email: "",
      carboncopy: "",
      phoneMask: "(###) ###-####",
      introduction: "",
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
  },
  methods: {
    ...mapActions(["updateInfluencer"]),
    open() {
      if (!this.influencer) return;
      this.dialog = true;
      this.phone = this.influencer.phone;
      this.email = this.influencer.email;
      this.carboncopy = this.influencer.carboncopy;
      this.introduction = this.influencer.introduction;
    },
    async submit() {
      if (!this.influencer) return;
      this.dialog = false;
      const options = {
        criteria: { _id: this.influencer._id },
        action: {
          $set: {
            phone: this.phone.replace(/[^\d]/g, ""),
            email: this.email,
            carboncopy: this.carboncopy,
            introduction: this.introduction,
          },
        },
      };
      const res = await this.axios.post("/influencer/accounts/update", options);
      this.updateInfluencer(res.data);
    },
  },
};
</script>
