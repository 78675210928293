<template lang="pug">
.my-2
  v-form(@submit.prevent="search()")
    v-text-field(
      v-model="searchText",
      label="Search Business",
      append-icon="search",
      @click:append="search()",
      solo,
      hide-details,
      dense
    )
  v-list.py-0(dense, max-height="200", style="overflow: scroll")
    v-list-item(
      v-for="(item, index) in bizs",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item.name }}
        v-list-item-subtitle(v-if="item.address") {{ item.short_name }} {{ item.address.city }}, {{ item.address.state }}
      v-list-item-action(v-if="item._id == selectedId")
        v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      bizs: [],
      selectedId: null,
    };
  },
  methods: {
    async search() {
      const searchText = this.searchText.trim();
      if (!searchText || searchText.length < 3) {
        alert("Please enter a name with at least 3 letters");
        return;
      }
      const options = {
        criteria: {
          name: { $regex: searchText, $options: "i" },
        },
        select: "name short_name address",
      };
      const res = await this.axios.post("/bizs/list", options);
      this.bizs = res.data;
    },
    select(biz) {
      if (!biz) return;
      this.selectedId = biz._id;
      this.$emit("select", biz);
    },
  },
};
</script>
