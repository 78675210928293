<template lang="pug">
.pa-3
  TopBar
  Basic
  Tiers
</template>

<script>
import TopBar from "./TopBar/index";
import Basic from "./Basic/index";
import Tiers from "./Tiers";

export default {
  components: { TopBar, Basic, Tiers },
};
</script>
