<template lang="pug">
.text-center.mt-3(v-if="!isComplete")
  v-btn.text-capitalize(@click="open()", color="secondary") {{ title }}
  v-dialog(v-model="dialog", max-width="600")
    v-card
      v-container
        v-radio-group(v-model="business_type", row, v-if="!stripeAccount")
          v-radio(
            label="Tax as an Individual",
            value="individual",
            color="secondary"
          )
          v-radio(label="Tax as a Company", value="company", color="secondary")
        IndividualAccount(
          v-if="this.business_type == 'individual'",
          @done="dialog = false",
          :dialog="dialog",
          :countriesStates="countriesStates"
        )
        CompanyAccount(
          v-if="this.business_type == 'company'",
          @done="dialog = false",
          :countriesStates="countriesStates"
        )
        span We use these information to produce 1099 tax form
</template>

<script>
import { mapGetters } from "vuex";
import IndividualAccount from "./IndividualAccount";
import CompanyAccount from "./CompanyAccount";
import MapUtils from "@/utils/map.js";

export default {
  name: "CreateStripeAccount",
  components: { IndividualAccount, CompanyAccount },
  data() {
    return {
      dialog: false,
      business_type: "company",
      countriesStates: [],
    };
  },
  methods: {
    open() {
      this.dialog = true;
      if (this.stripeAccount) {
        this.business_type = this.stripeAccount.business_type;
      }
    },
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
    title() {
      if (this.stripeAccount) return "Update Payout Account";
      else return "Create Payout Account";
    },
    isComplete() {
      // Create or update individual account
      // Create company account. No update allowed
      if (!this.stripeAccount) return false;
      if (this.stripeAccount.business_type == "individual") {
        return this.stripeAccount.individual.verification.status == "verified";
      }
      return true;
    },
  },
  mounted() {
    MapUtils.loadCountriesStates().then((data) => {
      this.countriesStates = data;
    });
  },
};
</script>
