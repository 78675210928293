<template lang="pug">
v-dialog(v-model="dialog", width="300", scrollable)
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit Logo
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :width="180",
          :height="180",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
        .caption Use mouse to move and zoom.
        .subtitle-2.my-3 Custom Background Color
        v-color-picker(
          v-model="canvas_color",
          hide-mode-switch,
          hide-input,
          hide-canvas,
          flat,
          show-swatches,
          mode="hexa",
          disabled
        )
    v-card-actions
      v-btn(@click="upload", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      myCroppa: {},
      loading: false,
      dialog: false,
      canvas_color: "#FFFFFF",
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
  },
  methods: {
    ...mapActions(["setInfluencer"]),
    open() {
      this.dialog = true;
    },
    async upload() {
      if (!this.influencer || !this.influencer._id) return;
      const bizId = this.influencer._id;
      let oldLogo = this.influencer.logo;

      this.loading = true;
      const updateInfluencer = this.updateInfluencer;
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.loading = false;
            this.myCroppa.chooseFile();
            return;
          }
          const fd = new FormData();
          fd.append("file", blob);
          fd.append("path", "public/img/influencer/logo/" + bizId);
          fd.append("acl", "public-read");
          fd.append("name", "upload.jpeg");
          this.axios.post("/pictures/create", fd).then((response) => {
            this.myCroppa.remove();
            const files = response.data.files;
            if (!files || files.length === 0) return;
            const options = {
              criteria: { _id: this.influencer._id },
              action: { $set: { logo: files[0] } },
            };
            this.axios
              .post("/influencer/accounts/update", options)
              .then((response) => {
                setTimeout(() => {
                  updateInfluencer(response.data);
                }, 500);
                this.removeOld(oldLogo);
              });
          });
        },
        "image/jpeg",
        0.8
      );
    },
    updateInfluencer(data) {
      this.loading = false;
      this.setInfluencer(data);
      this.dialog = false;
    },
    removeOld(path) {
      if (!path || !path.length) return;
      this.axios.post("/pictures/remove", { imagePath: path });
    },
  },
  mounted() {
    EventBus.$on("edit-influencer-logo", this.open);
  },
  destroyed() {
    EventBus.$off("edit-influencer-logo");
  },
};
</script>