import Vue from "vue";
import Router from "vue-router";
import Home from '@/components/Home/index.vue'
import Profile from '@/components/Profile/index.vue'
import Shop from '@/components/Shop/index.vue'
import userRoute from '@/components/User/route'
import Persons from './../components/Persons/index.vue'
import financesRoute from '@/components/Finances/route'

Vue.use(Router)

const baseRoutes = [{
  path: "/",
  name: "Home",
  component: Home
}, {
  path: '/profile',
  component: Profile,
  meta: {
    auth: true
  }
}, {
  path: '/shop',
  component: Shop,
  meta: {
    auth: true
  }
}, {
  path: '/persons',
  component: Persons,
  meta: {
    auth: true
  }
}]

const routes = baseRoutes.concat(userRoute, financesRoute)

export default new Router({
  mode: "history",
  linkActiveClass: "is-active",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
});