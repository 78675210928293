import _ from "underscore";

const state = {
  influencerPersonList: [],
  influencer: null,
  influencerShop: null,
  influencers: [],
  influencerRole: null
}

const getters = {
  influencerPersonList: state => state.influencerPersonList,
  influencers: state => state.influencers,
  influencer: state => state.influencer,
  influencerShop: state => state.influencerShop,
  influencerRole: state => state.influencerRole
}

const actions = {
  setInfluencerPersonList: ({
    commit
  }, data) => {
    commit('setInfluencerPersonList', data)
  },
  setInfluencers: ({
    commit
  }, data) => {
    commit('setInfluencers', data)
  },
  updateInfluencer: ({
    commit
  }, data) => {
    commit("updateInfluencer", data);
  },
  addInfluencer: ({
    commit
  }, data) => {
    commit("addInfluencer", data);
  },
  removeInfluencer: ({
    commit
  }, data) => {
    commit("removeInfluencer", data);
  },
  setInfluencer: ({
    commit
  }, data) => {
    commit('setInfluencer', data)
  },
  setInfluencerShop: ({
    commit
  }, data) => {
    commit('setInfluencerShop', data)
  },
  setInfluencerRole: ({
    commit
  }, data) => {
    commit('setInfluencerRole', data)
  }
}

const mutations = {
  setInfluencerPersonList(state, data) {
    state.influencerPersonList = data
  },
  setInfluencers(state, data) {
    state.influencers = data
  },
  updateInfluencer(state, data) {
    state.influencers = _.map(state.influencers, o => {
      if (o._id === data._id) return data;
      return o;
    });
    state.influencer = data
  },
  addInfluencer(state, data) {
    state.influencers.push(data);
    state.influencer = data
  },
  removeInfluencer(state, data) {
    state.influencers = _.reject(state.influencers, o => {
      return o._id === data._id;
    });
    state.influencer = null
  },
  setInfluencer(state, item) {
    state.influencer = item
  },
  setInfluencerShop(state, item) {
    state.influencerShop = item
  },
  setInfluencerRole(state, role) {
    state.influencerRole = role
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}