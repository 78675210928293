<template lang="pug">
v-card(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 {{ item.name }}
    v-spacer
    v-btn.text-notransform(@click="edit()", outlined, small, color="secondary")
      span Edit
      v-icon(right, small) mdi-pencil
  v-card-text
    div {{ item.introduction }}
    v-row(wrap, no-gutters)
      v-col(cols="4", v-for="(logo, index) in logos", :key="index")
        v-img(:src="logo")
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  props: ["item"],
  computed: {
    ...mapGetters(["bizs"]),
    logos() {
      let list = [];
      _.map(this.item.bizs, (o) => {
        const found = _.find(this.bizs, (biz) => biz._id == o);
        if (found) {
          list.push("https://media.selflane.com/" + found.logo);
        }
      });
      return _.first(list, 9);
    },
  },
  methods: {
    edit() {
      EventBus.$emit("edit-influencer-shop", this.item);
    },
  },
};
</script>
