<template lang="pug">
div
  v-btn.text-capitalize(
    @click="open()",
    outlined,
    small,
    depressed,
    color="grey darken-2"
  ) Edit
    v-icon(right, small) mdi-pencil
  v-dialog(v-model="dialog", width="350")
    v-form(@submit.prevent="submit")
      v-card
        v-toolbar(flat, dense)
          .subtitle-2 Commission Tiers
          v-spacer
          v-btn.text-notransform(
            outlined,
            rounded,
            x-small,
            color="secondary",
            @click="more()"
          )
            v-icon(small, left) mdi-plus
            span Add
        v-card-text
          div(v-for="(item, index) in commission_tiers", :key="index")
            v-row
              v-col(cols="3")
                .subtitle-2 Tier {{ index + 1 }}
              v-col(cols="9")
                v-select(
                  label="Commission Rate",
                  v-model="item.commission_rate",
                  :items="rates"
                )
                v-textarea(label="Description", v-model="item.description")
        v-card-actions
          v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      commission_tiers: [],
      rates: _.range(5, 31),
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
  },
  methods: {
    ...mapActions(["updateInfluencer"]),
    open() {
      if (!this.influencer) return;
      this.dialog = true;
      this.commission_tiers = JSON.parse(
        JSON.stringify(this.influencer.commission_tiers)
      );
    },
    more() {
      this.commission_tiers.push({
        commission_rate: 10,
        description: "",
      });
    },
    async submit() {
      if (!this.influencer) return;
      this.dialog = false;
      const options = {
        criteria: { _id: this.influencer._id },
        action: { $set: { commission_tiers: this.commission_tiers } },
      };
      const res = await this.axios.post("/influencer/accounts/update", options);
      this.updateInfluencer(res.data);
    },
  },
};
</script>
