<template lang="pug">
v-card.my-3(outlined)
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      v-spacer
      Edit
    v-divider.mt-2
  v-list(dense, v-if="influencer")
    v-list-item
      v-list-item-content
        v-list-item-title Phone
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ influencer.phone | phone }}
    v-list-item
      v-list-item-content
        v-list-item-title Email
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ influencer.email }}
    v-list-item
      v-list-item-content
        v-list-item-title CC Recipients
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ influencer.carboncopy }}
    v-list-item
      v-list-item-content
        v-list-item-title Introduction
      v-list-item-content.align-end
        v-list-item-subtitle.text-right {{ influencer.introduction }}
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./Edit";

export default {
  components: { Edit },
  computed: { ...mapGetters(["influencer"]) },
};
</script>
