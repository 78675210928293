<template lang="pug">
v-container(v-if="!loading && $auth.check()")
  v-card(outlined, v-if="hasAccount")
    template(v-for="(item, index) in influencers")
      v-divider(v-if="index != 0")
      v-list-item(@click="handleSelect(item)")
        v-list-item-content {{ item.name }}
  .my-10.text-center.subtitle-2(v-else) Thanks for visiting. You don't have access to any influencer service.
  Create
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import Create from "./Create";

export default {
  components: { Create },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["influencers", "influencer", "influencers"]),
    hasAccount() {
      return this.influencers && this.influencers.length;
    },
  },
  methods: {
    ...mapActions([
      "setInfluencerPersonList",
      "setInfluencers",
      "setInfluencer",
      "setInfluencerRole",
    ]),
    async load() {
      if (!this.$auth.check()) return;
      this.loading = true;
      const user = this.$auth.user();
      const options = { criteria: { user: user._id } };
      const res = await this.axios.post("/influencer/persons/list", options);
      this.setInfluencerPersonList(res.data);
      const ids = _.pluck(res.data, "influencer");
      this.loadInfluencers(ids);
      this.loading = false;
    },
    async loadInfluencers(ids) {
      if (!ids || !ids.length) return;
      const options = { criteria: { _id: { $in: ids } } };
      const res = await this.axios.post("/influencer/accounts/list", options);
      this.setInfluencers(res.data);
      if (this.influencer) {
        const found = _.find(
          this.influencers,
          (o) => o._id == this.influencer._id
        );
        if (found) this.handleSelect(found);
        else this.presetInfluencer();
      } else {
        this.presetInfluencer();
      }
    },
    presetInfluencer() {
      if (this.influencers && this.influencers.length) {
        this.handleSelect(this.influencers[0]);
      }
    },
    handleSelect(item) {
      this.setInfluencer(item);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
