<template lang="pug">
v-row(justify="space-between", wrap, v-if="stripeAccount")
  v-col(align="center", cols="6", md="3")
    .caption.sl-secondary-text Type
    .subtitle-2.text-capitalize {{ stripeAccount.business_type }}
  v-col(align="center", cols="6", md="3")
    .caption.sl-secondary-text Account
    .subtitle-2 {{ accountName }}
  v-col(align="center", cols="6", md="3")
    .caption.sl-secondary-text Schedule
    .subtitle-2.text-capitalize {{ payout }}
  v-col(
    align="center",
    cols="6",
    md="3",
    v-if="stripeAccount.business_type == 'individual'"
  )
    .caption.sl-secondary-text Status
    .subtitle-2.text-capitalize {{ status }}
  v-col(align="center", cols="6", md="3", v-if="!isComplete")
    .caption.sl-secondary-text Currently Due
    div(
      v-for="(item, index) in stripeAccount.requirements.currently_due",
      :key="index"
    ) {{ item }}
    v-btn(icon, small, color="green", :loading="loading", @click="load()")
      v-icon(small) sync
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount", "deliv"]),
    accountName() {
      if (this.stripeAccount) {
        if (this.stripeAccount.business_type == "company")
          return this.stripeAccount.company.name;
        else
          return (
            this.stripeAccount.individual.first_name +
            " " +
            this.stripeAccount.individual.last_name
          );
      }
      return "";
    },
    payout() {
      if (this.stripeAccount) {
        if (this.stripeAccount.payouts_enabled)
          return (
            this.stripeAccount.settings.payouts.schedule.interval +
            "/" +
            this.stripeAccount.settings.payouts.schedule.weekly_anchor
          );
      }
      return "Not Enabled";
    },
    status() {
      if (this.stripeAccount && this.stripeAccount.individual) {
        return this.stripeAccount.individual.verification.status;
      }
      return "";
    },
    isComplete() {
      return (
        this.stripeAccount &&
        this.stripeAccount.requirements &&
        !this.stripeAccount.requirements.currently_due.length
      );
    },
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    load() {
      if (!this.deliv) this.loading = true;
      const data = { delivId: this.deliv._id };
      this.axios
        .post("/stripe/accounts/retrieve", data)
        .then((response) => {
          this.loading = false;
          this.setStripeAccount(response.data);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
