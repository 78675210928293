<template lang="pug">
.text-center
  .influencer-logo(@click="handleEdit")
    v-img(:src="logo")
    .influencer-logo-icon.d-flex.flex-row.align-center
      v-icon(style="margin-left: auto; margin-right: auto") mdi-pencil
  .influencer-name {{ influencer.name }}
  .influencer-code Code: {{ influencer.code }}
  .influencer-code Level: {{ influencer.level | level }}
  v-divider
  Form
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Form },
  filters: {
    level(value) {
      switch (value) {
        case 0:
          return "Green";
        case 1:
          return "Gold";
        case 2:
          return "Platinum";
      }
    },
  },
  computed: {
    ...mapGetters(["influencer"]),
    logo() {
      return "https://media.selflane.com/" + this.influencer.logo;
    },
  },
  methods: {
    handleEdit() {
      EventBus.$emit("edit-influencer-logo");
    },
  },
};
</script>

<style scoped>
.influencer-logo {
  margin-left: auto;
  margin-right: auto;
  height: 180px;
  width: 180px;
  position: relative;
  border: 5px solid white;
  border-radius: 90px;
  cursor: pointer;
  overflow: hidden;
}
.influencer-logo-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
.influencer-name {
  font-size: 32px;
  font-weight: 600;
  margin: 10px;
} 
.influencer-code {
  font-size: 20px;
  font-weight: 600;
  margin: 10px;
  color: grey;
}  
</style>