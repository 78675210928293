<template lang="pug">
v-container(v-if="!$auth.check()")
  .d-flex.flex-row.justify-center.my-10
    v-card(
      max-width="500",
      width="90%",
      color="rgba(255, 255, 255, 0.7)",
      shaped
    )
      .text-center.pa-8
        .title Selflane for Influencers
        v-btn.text-capitalize.my-10(
          to="/login",
          large,
          color="secondary",
          depressed,
          rounded
        ) Influencer Login
        .body-2 If you're looking for other services, please visit
        v-btn(href="https://selflane.com", text) selflane.com
</template>

<script>
export default {};
</script>
