/// can access in creating an account

<template lang="pug">
v-form(@submit.prevent="submit")
  v-row(dense)
    v-col(cols="3") Company
    v-col
      v-row(dense)
        v-col(cols="6")
          v-text-field(
            label="Company Name",
            v-model="company.name",
            :hint="name_hint"
          )
        v-col(cols="6")
          v-text-field(
            label="Company Phone",
            v-model="company.phone",
            v-mask="\"(###) ###-####\""
          )
        v-col(cols="6")
          v-text-field(:label="tax_id_label", v-model="company.tax_id")
        v-col(cols="6", v-if="useVAT")
          v-text-field(label="VAT Number", v-model="company.vat_id")
  v-row(dense)
    v-col(cols="3") Address
    v-col
      v-row(dense)
        v-col(cols="9")
          v-text-field(
            label="Street",
            v-model="address.line1",
            placeholder="",
            ref="autocomplete",
            @keydown.enter.prevent=""
          )
        v-col(cols="3")
          v-text-field(label="Suite, Room", v-model="address.line2")
        v-col(cols="6")
          v-select(
            label="Country",
            name="country",
            v-model="address.country",
            :items="countriesStates",
            item-text="name",
            item-value="abbreviation"
          )
        v-col(cols="6")
          v-select(
            label="State",
            name="state",
            v-model="address.state",
            :items="stateItems",
            item-text="name",
            item-value="abbreviation"
          )
        v-col(cols="6")
          v-text-field(label="City", v-model="address.city")
        v-col(cols="6")
          v-text-field(label="Zipcode", v-model="address.postal_code")
  v-list(dense)
    v-list-item
      v-list-item-action
        v-checkbox(v-model="agreed", color="secondary")
      v-list-item-content(@click.prevent="agreed = !agreed")
        StripeToS
  v-btn(
    block,
    type="submit",
    color="secondary",
    :loading="loading",
    :disabled="!agreed"
  ) save
  .red--text {{ errors }}
</template>

<script>
import StripeToS from "./StripeToS";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  name: "StripeCompanyAccount",
  props: ["countriesStates"],
  components: { StripeToS },
  data() {
    return {
      address: {
        line1: "",
        line2: "",
        postal_code: "",
        city: "",
        state: "",
        country: "US",
      },
      company: {
        name: "",
        phone: "",
        tax_id: "",
        vat_id: "",
      },
      agreed: false,
      loading: false,
      errors: null,
      name_hint:
        "Use the exact name that matches company tax ID. Do not include DBA, etc.",
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
    stateItems() {
      if (!this.countriesStates || !this.address) return [];
      const country = _.find(
        this.countriesStates,
        (o) => o.abbreviation == this.address.country
      );
      if (!country) return [];
      return country.states;
    },
    tax_id_label() {
      if (this.address.country == "CA") return "BI: Business Number";
      else return "EIN: Employer ID Number";
    },
    useVAT() {
      if (this.address.country == "CA") return true;
      else return false;
    },
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    validate() {
      if (
        !this.address ||
        !this.address.country ||
        !this.address.line1 ||
        !this.address.city
      )
        return "Invalid Address";
      if (!this.company.name) return "Company name is required";
      if (!this.company.phone) return "Company phone is required";
      if (!this.company.tax_id) return this.tax_id_label + " is required";
      if (this.useVAT && !this.company.vat_id) return "VAT number is required";
      return null;
    },
    submit() {
      if (!this.influencer) return;
      this.errors = this.validate();
      if (this.errors) return;
      this.loading = true;
      const stripeData = {
        country: this.address.country,
        business_type: "company",
        company: {
          address: this.address,
          name: this.company.name,
          phone: this.company.phone,
          tax_id: this.company.tax_id,
          vat_id: this.company.vat_id,
        },
      };
      const options = {
        influencerId: this.influencer._id,
        stripeData: stripeData,
      };
      this.axios
        .post("/stripe/accounts/create", options)
        .then((response) => {
          this.loading = false;
          this.setStripeAccount(response.data);
          this.$emit("done");
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) this.errors = err.response.data;
        });
    },
    setWithGooglePlace(place) {
      // address components
      const street_number_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "street_number") != undefined;
      });
      const route_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "route") != undefined;
      });
      const locality_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "locality") != undefined;
      });
      const administrative_area_level_1_component = _.find(
        place.address_components,
        (o) => {
          return (
            _.find(o.types, (t) => t == "administrative_area_level_1") !=
            undefined
          );
        }
      );
      const country_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "country") != undefined;
      });
      const postal_code_component = _.find(place.address_components, (o) => {
        return _.find(o.types, (t) => t == "postal_code") != undefined;
      });
      this.address.line1 =
        street_number_component.short_name + " " + route_component.short_name;
      this.address.city = locality_component.short_name;
      this.address.state = administrative_area_level_1_component.short_name;
      this.address.country = country_component.short_name;
      this.address.postal_code = postal_code_component.short_name;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete.$refs.input
      // { types: ["geocode"] }
    );
    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });
    this.autocomplete.setFields(["address_component", "geometry", "place_id"]);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.setWithGooglePlace(place);
    });
  },
};
</script>
