<template lang="pug">
v-card.my-3
  v-card-text
    .caption.sl-secondary-text Tax File
    v-spacer
    v-divider.my-2
    .caption The 1099-K file is based on the deposits we send to your account in a year.
  Table(:items='taxfiles')
</template>

<script>
import Table from "./Table";
import { mapGetters } from "vuex";

export default {
  components: { Table },
  data() {
    return {
      taxfiles: []
    };
  },
  computed: {
    ...mapGetters(["deliv"])
  },
  methods: {
    load() {
      if (!this.deliv) return;
      const options = {
        criteria: {
          deliv: this.deliv._id
        }
      };
      this.axios.post("/tax/taxfile/list", options).then(response => {
        this.taxfiles = response.data;
      });
    }
  },
  mounted() {
    this.load();
  }
};
</script>
