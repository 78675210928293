<template lang="pug">
.pa-3
  .d-flex.flex-row.justify-space-between 
    .sl-title My Shop
    AddBtn
  v-divider.my-2
  Groups
  Form
</template>

<script>
import AddBtn from "./AddBtn";
import Groups from "./Groups/index";
import Form from "./Form";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  components: { AddBtn, Groups, Form },
  computed: {
    ...mapGetters(["influencer", "influencerShop"]),
  },
  methods: {
    ...mapActions(["setInfluencerShop", "setBizs"]),
    async load() {
      if (!this.influencer) return;
      const params = {
        criteria: { influencer: this.influencer._id },
      };
      const res = await this.axios.post("/influencer/shops/retrieve", params);
      this.setInfluencerShop(res.data);
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.influencerShop) return;
      const ids = _.chain(this.influencerShop.groups)
        .pluck("bizs")
        .flatten()
        .compact()
        .value();
      const options = {
        criteria: { _id: { $in: ids } },
        select: "name logo",
      };
      try {
        const res = await this.axios.post("/bizs/list", options);
        this.setBizs(res.data);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>
