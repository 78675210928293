<template lang="pug">
v-card.my-3
  v-card-text
    .d-flex.flex-row.align-center
      .caption.sl-secondary-text Balance
      v-spacer
      YearSelector(@select="load")
    v-divider.mt-2
    Balance
  Transactions(:year='year')
</template>

<script>
import YearSelector from "@/components/basic/YearSelector";
import Balance from "./Balance";
import Transactions from "./Transactions";

export default {
  components: { YearSelector, Balance, Transactions },
  data() {
    return {
      year: null,
      transactions: null,
    };
  },
  methods: {
    load(year) {
      this.year = year;
    },
  },
};
</script>
