<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="group")
    v-toolbar(flat, dense)
      .subtitle-2 Shop Group
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(label="Name", v-model.trim="group.name")
        v-textarea(label="Introduction", v-model.trim="group.introduction")
        v-simple-table.mb-3(dense)
          tbody
            tr(
              v-for="(item, index) in group.bizs",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-icon(small) mdi-menu
              td {{ bizName(item) }}
              td.text-right
                v-btn(icon, small, @click="removeBiz(index)")
                  v-icon(small, color="error") mdi-delete
        BizSelector(@select="selectBiz")
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        .mt-5.text-center(v-if="group._id")
          .caption If you want to delete this group, please click below.
          v-btn(color="error", block, text, @click="handleDelete()") Delete Group
</template>

<script>
import { EventBus } from "@/event-bus.js";
import BizSelector from "@/components/basic/BizSelector";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BizSelector },
  data() {
    return {
      group: null,
      dialog: false,
      errors: [],
      loading: false,
      defaultBizs: [],
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
  },
  methods: {
    ...mapActions(["setInfluencerShop"]),
    open(val) {
      if (!val) {
        this.group = {
          name: "",
          introduction: "",
          bizs: [],
        };
      } else {
        this.group = JSON.parse(JSON.stringify(val));
      }
      this.errors = [];
      this.dialog = true;
      this.loadDefault();
    },
    async loadDefault() {
      if (!this.group || !this.group.bizs) return;
      const options = {
        criteria: { _id: { $in: this.group.bizs } },
        select: "name",
      };
      const res = await this.axios.post("/bizs/list", options);
      this.defaultBizs = res.data;
    },
    bizName(id) {
      if (!this.defaultBizs) return id;
      const found = _.find(this.defaultBizs, (o) => o._id == id);
      if (found) return found.name;
      else return id;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend(e) {
      e.target.style.opacity = 1;
    },
    drop(index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.group.bizs.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.group.bizs.length
      )
        return;
      let bizs = JSON.parse(JSON.stringify(this.group.bizs));
      let tmp = JSON.parse(JSON.stringify(bizs[this.draggingIndex]));
      bizs.splice(this.draggingIndex, 1);
      bizs.splice(index, 0, tmp);
      this.group.bizs = bizs;
    },
    removeBiz(index) {
      this.group.bizs.splice(index, 1);
    },
    selectBiz(biz) {
      if (!this.group || !biz) return;
      if (!this.group.bizs) this.group.bizs = [biz._id];
      if (this.group.bizs.indexOf(biz._id) < 0) this.group.bizs.push(biz._id);
      this.defaultBizs.push(biz);
    },
    async submit() {
      this.errors = [];
      let params;
      if (!this.group._id) {
        params = {
          criteria: { influencer: this.influencer._id },
          action: { $push: { groups: this.group } },
        };
      } else {
        params = {
          criteria: {
            influencer: this.influencer._id,
            "groups._id": this.group._id,
          },
          action: { $set: { "groups.$": this.group } },
        };
      }
      this.loading = true;
      const res = await this.axios.post("/influencer/shops/update", params);
      this.setInfluencerShop(res.data);
      this.loading = false;
      this.dialog = false;
    },
    handleDelete() {
      if (!this.group._id) return;
      confirm("Do you want to delete this group? This can not be reverted!") &&
        this.deleteGroup();
    },
    async deleteGroup() {
      if (!this.group._id) return;
      const params = {
        criteria: { influencer: this.influencer._id },
        action: { $pull: { groups: { _id: this.group._id } } },
      };
      this.loading = true;
      try {
        await this.axios.post("/influencer/shops/update", params);
        this.setInfluencerShop(this.group);
        this.dialog = false;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("edit-influencer-shop", this.open);
  },
  destroyed() {
    EventBus.$off("edit-influencer-shop");
  },
};
</script>