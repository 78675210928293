<template lang="pug">
v-card.my-3(outlined)
  v-toolbar(flat, dense)
    .subtitle-2 Commission Tiers
    v-spacer
    Edit
  v-simple-table(dense, v-if="influencer")
    thead
      tr
        th Tier
        th Commission
        th Description
    tbody
      tr(v-for="(item, index) in influencer.commission_tiers", :key="index")
        td {{ index + 1 }}
        td {{ item.commission_rate }}%
        td {{ item.description }}
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./Edit";

export default {
  components: { Edit },
  computed: { ...mapGetters(["influencer"]) },
};
</script>
