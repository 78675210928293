<template lang="pug">
v-btn(icon, @click="handleRemove()", v-if="!isMe")
  v-icon(color="error") mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeletePerson",
  props: ["person"],
  computed: {
    isMe() {
      return (
        this.person &&
        this.person.user &&
        this.person.user == this.$auth.user()._id
      );
    },
  },
  methods: {
    ...mapActions(["removePerson"]),
    handleRemove() {
      confirm("Do you want to delete this account?") && this.remove();
    },
    async remove() {
      if (!this.person) return;
      const params = { id: this.person._id };
      try {
        await this.axios.post("/influencer/persons/deleteOne", params);
        this.removePerson(this.person);
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
    },
  },
};
</script>
