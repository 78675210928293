<template lang="pug">
div
  v-navigation-drawer(
    v-model="drawer",
    app,
    fixed,
    v-if="$auth.check()",
    :clipped="$vuetify.breakpoint.lgAndUp",
    width="250"
  )
    v-list(dense, expand, nav)
      v-list-group(
        v-for="item in items",
        v-model="item.active",
        :key="item.title",
        :prepend-icon="item.action",
        no-action
      )
        template(v-slot:activator)
          v-list-item-title {{ item.title }}
        v-list-item(
          v-for="subItem in item.items",
          :key="subItem.title",
          :to="subItem.to"
        )
          v-list-item-content
            v-list-item-title {{ subItem.title }}
      v-list-item(@click="logout")
        v-list-item-content
          v-list-item-title.body-2 Sign Out
          v-list-item-subtitle.caption {{ $auth.user().email }}
        v-list-item-action
          v-icon mdi-logout
  v-app-bar(
    app,
    dense,
    dark,
    flat,
    color="primary",
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  )
    v-app-bar-nav-icon(@click="drawer = !drawer", v-if="$auth.check()")
    v-btn(icon, @click="toHome")
      v-icon $selflane
    .title.hidden-xs-only Selflane Influencer
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavGroups from "@/components/basic/NavGroups.json";

export default {
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    ...mapGetters(["influencer"]),
    items() {
      if (!this.$auth.user() || !this.influencer) return [];
      const groups = JSON.parse(JSON.stringify(NavGroups));
      return groups;
    },
  },
  methods: {
    ...mapActions([
      "setInfluencers",
      "setInfluencer",
      "setInfluencerPersonList",
    ]),
    toHome() {
      this.$router.push("/");
    },
    logout() {
      this.setInfluencers(null);
      this.setInfluencer(null);
      this.setInfluencerPersonList([]);
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      // eslint-disable-next-line
      gapi.load("auth2", initAuth2);
      const finish = this.$auth.logout();
      function initAuth2() {
        // eslint-disable-next-line
        gapi.auth2.init({ client_id: clientId }).then(() => {
          // eslint-disable-next-line
          gapi.auth2.getAuthInstance().signOut();
          finish;
        });
      }
    },
  },
};
</script>
