<template lang="pug">
v-btn.text-notransform(@click="add", outlined, small, color="secondary")
  span Add
  v-icon(right, small) mdi-plus
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    add() {
      EventBus.$emit("edit-influencer-shop");
    },
  },
};
</script>
