<template lang="pug">
v-data-table(
  :headers="headers",
  :items="persons",
  sort-by="email",
  hide-default-footer
)
  template(v-slot:item.phone="{ item }")
    span {{ item.phone | phone }}
  template(v-slot:item.actions="{ item }")
    Edit.mr-3(:person="item")
    Delete(:person="item", v-if="persons && persons.length > 1")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Edit from "./Edit";
import Delete from "./Delete";

export default {
  components: { Edit, Delete },
  data() {
    return {
      headers: [
        { text: "User", value: "email" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
        { text: "", value: "actions", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["influencer", "persons"]),
  },
  methods: {
    ...mapActions(["setPersons"]),
    async load() {
      if (!this.influencer) return;
      const options = {
        criteria: { influencer: this.influencer._id },
      };
      const res = await this.axios.post("influencer/persons/list", options);
      this.setPersons(res.data);
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-influencer", this.load);
  },
  destroyed() {
    EventBus.$off("switch-influencer");
  },
};
</script>
