<template lang="pug">
v-row(v-if="influencerShop")
  v-col(
    cols="12",
    sm="6",
    md="4",
    lg="3",
    v-for="(item, index) in influencerShop.groups",
    :key="index"
  )
    Cell(:item="item")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  computed: { ...mapGetters(["influencerShop"]) },
};
</script>
